/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import * as finmath from '@mod-system/js/util/finmath';
import * as merge from 'dompack/extra/merge';
import * as whintegration from '@mod-system/js/wh/integration';


export function _updateAddedProductPrice(webshop, node) {
  const lineuid = node.dataset.lineuid;
  const amount = Number(node.dataset.amount) || 1;

  if (!lineuid || !amount)
    return;

  const prod = webshop.getCartProductByLineUid(lineuid);

  if (!prod)
    return;

  merge.run(node,
    {
      amount: amount,
      itemprice: webshop.formatPrice(prod.itemprice),
      totalprice: webshop.formatPrice(finmath.multiply(prod.itemprice, amount))
    });
}

export function updateAddedProductFromEvent(evt) {
  // Need 'addedproductinfo' (added by cross-sell pages)
  if (!whintegration.config.obj.addedproductinfo)
    return;

  const webshop = evt.detail.webshop;
  const prod = webshop.getCartProductByLineUid(evt.detail.lineuid);
  console.log(evt.detail, prod);
  if (!prod)
    return;

  const prodinfo = whintegration.config.obj.addedproductinfo.find(e => e.product === prod.product);
  console.log(prodinfo, whintegration.config.obj.addedproductinfo);
  if (!prodinfo)
    return;

  for (const node of Array.from(document.querySelectorAll(".webshop-addedcartproduct"))) {
    merge.run(node,
      {
        amount: evt.detail.amount,
        itemprice: webshop.formatPrice(prod.itemprice),
        totalprice: webshop.formatPrice(finmath.multiply(prod.itemprice, evt.detail.amount)),
        title: prodinfo.title,
        image: prodinfo.image,
        imagetitle: prodinfo.imagetitle
      });
  }
}
