//@ts-nocheck -- converted from ES
import * as dompack from 'dompack';
import * as formrpc from "@mod-publisher/js/forms/rpc";
import * as whintegration from '@mod-system/js/wh/integration';

let config: {
  title: string;
  orderdetails: unknown;
  structured: unknown;
} | undefined;

async function submitQuoteRequest(evt: HTMLEvent) {
  dompack.stop(evt);

  const form = evt.target;
  const producttitle = config?.title ?? document.querySelector(".webshop-product__title")?.textContent ?? document.title;
  const productamount = (document.querySelector("#amount")) ? document.querySelector("#amount").value : "zie specificaties" ;


  const fields = { firstname:    form.elements.firstName.value,
                lastname:     form.elements.lastName.value,
                company:      form.elements.company.value,
                email:        form.elements.email.value,
                phonenumber:  form.elements.phoneNumber.value,
                street:       form.elements.streetName?.value ?? "null",
                housenumber:  form.elements.streetNumber?.value ?? "null",
                zipcode:      form.elements.zipcode?.value ?? "null",
                city:         form.elements.city?.value ?? "null",
                comments:     form.elements.comments.value,
                producturl:   window.location.href,
                product:      producttitle,
                amount:       productamount,
                orderdetails: config?.orderdetails ?? producttitle,
                structured:   config?.structured ?? "null"};

  if(form.elements.print.files.length) //read <input> as data: url
    fields.print = await new Promise(resolve => {
      const reader = new FileReader();
      reader.onloadend = () => resolve({link: reader.result, filename: form.elements.print.files[0].name});
      reader.readAsDataURL(form.elements.print.files[0]);
    });

  const result = await formrpc.submitForm(whintegration.config.site.requestquoteformtarget, fields, config?.structured ? { extrasubmit: JSON.parse(config.structured) } : {});
  const redirecturl = document.getElementById('redirecturl').value;

  if(result.success)
    // eslint-disable-next-line require-atomic-updates  -- should navigateTo but that might not work until WH5.3 ?
    window.location = redirecturl;
  else
    // eslint-disable-next-line no-alert
    alert("Resultaat: " + JSON.stringify(result));
}

export function configureQuote(title: string, orderdetails: unknown, structuredorderdetails: unknown) {
  config = { title, orderdetails, structured: JSON.stringify(structuredorderdetails) };
}

dompack.register('#offerteForm', node => node.addEventListener("submit", evt => submitQuoteRequest(evt)));
