import "./header.scss";
import * as dompack from 'dompack';

dompack.onDomReady(() =>
{
  const body = document.body;
  const nav = dompack.qS("#header");
  const scrollUp = "scroll-up";
  const scrollDown = "scroll-down";
  let lastScroll = 0;

  window.addEventListener("scroll", () => {
    const currentScroll = window.pageYOffset;

    if (currentScroll <= 0) {
      body.classList.remove(scrollUp);
      return;
    }

    if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
      // down

      if (currentScroll <= 105) {
        body.classList.remove(scrollUp);
        return;
      }

      body.classList.remove(scrollUp);
      body.classList.add(scrollDown);
    } else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
      // up
      body.classList.remove(scrollDown);
      body.classList.add(scrollUp);
    }
    lastScroll = currentScroll;
 
  })
});


function addDropdownAttribute()
{
  let toplevelmenuitem = dompack.qSA('#sticky .dropdown-toggle');

  if(!window.matchMedia("(max-width:1200px)").matches)
    return;

  for (var i=0; i < toplevelmenuitem.length; i++)
  {
      toplevelmenuitem[i].setAttribute("data-toggle", "dropdown");
      toplevelmenuitem[i].classList.add("dropdown-toggle");
  }
}

dompack.onDomReady(addDropdownAttribute);
