import "./forms.scss";
import * as dompack from 'dompack';
import * as forms from "@mod-publisher/js/forms";

//Load neutral styling (optional, but you'll need to supply your own styling for some of the fields below if you skip this)
// import '@mod-publisher/js/forms/themes/neutral';

//Optionally: replaces upload fields with a nicer and edit-supporting version
import UploadField from '@mod-publisher/js/forms/fields/upload';
dompack.register(".wh-form__upload", node => new UploadField(node));

//Enable the imgedit and/or rtd fields:
import ImgEditField from '@mod-publisher/js/forms/fields/imgedit';
dompack.register(".wh-form__imgedit", node => new ImgEditField(node));

import RTDField from '@mod-publisher/js/forms/fields/rtd';
dompack.register(".wh-form__rtd", node => new RTDField(node));


forms.setup({ validate: true });
