import * as dompack from "@mod-system/js/dompack";
import ProductListDynPriceHandler from "./pricecalculation";
import { CategoryFilter, CategoryOptionFilter } from "./types";

export class FilterListener {
  private readonly pricehandler: ProductListDynPriceHandler;
  private scheduledcheck: NodeJS.Timeout | null = null;
  private lastfiltersetting: string | null = null;
  private readonly hashvar: string;

  constructor(pricehandler: ProductListDynPriceHandler, hashvar: string) {
    this.pricehandler = pricehandler;
    this.hashvar = hashvar;

    addEventListener("change", evt => this.onPossibleFilterChange(evt), true);
    addEventListener("input", evt => this.onPossibleFilterChange(evt), true);

    if (this.hashvar)
      this.applyFromHash();

    this.checkFilters();
  }

  private applyFromHash() {
    const cursetting = new URLSearchParams(location.hash.substring(1)).get(this.hashvar);
    if (!cursetting)
      return;

    const toactivate = cursetting.split(",");
    dompack.qSA<HTMLInputElement>(`input[data-webshop-filter-value]`).forEach(node => node.checked = toactivate.includes(node.dataset.webshopFilterValue!.split(':')[0]));
    dompack.qSA<HTMLOptionElement>(`option[data-webshop-filter-value]`).forEach(node => node.selected = toactivate.includes(node.dataset.webshopFilterValue!.split(':')[0]));
  }

  private onPossibleFilterChange(evt: Event) {
    if ((evt.target as HTMLElement)?.closest('[data-webshop-filter]'))
      this.scheduleFilterCheck();
  }

  private scheduleFilterCheck() {
    if (this.scheduledcheck)
      return;

    const lock = dompack.flagUIBusy();
    this.scheduledcheck = setTimeout(() => this.checkFilters().finally(() => {
      lock.release();
      this.scheduledcheck = null;
    }), 0);
  }

  private getCurrentFilters(): CategoryFilter {
    const filtersettingids = [];
    const optionfilters: CategoryOptionFilter[] = [];

    for (const filter of dompack.qSA('[data-webshop-filter]')) { //check all option sets
      const [filterid, optionlists_str] = filter.dataset.webshopFilter!.split(':');
      const optionlists = optionlists_str.split(",").map(str => parseInt(str));
      const optionvalues = new Set<number>();

      void (filterid); //not used yet? as filtervalue is sufficiently unique

      const selected = dompack.qSA(filter, "[data-webshop-filter-value]:checked");
      if (!selected.length) //TODO can we get eslint to reject if we forget .length here? didn't manage to enable https://typescript-eslint.io/rules/no-unnecessary-condition/
        continue; //ignore this filter

      for (const input of selected) { //and process the individual options
        const [filtervalue, optionids] = input.dataset.webshopFilterValue!.split(':');
        if (filtervalue)
          filtersettingids.push(parseInt(filtervalue));
        for (const optionid of optionids.split(','))
          optionvalues.add(parseInt(optionid));
      }

      optionfilters.push({ optionlists, optionvalues: Array.from(optionvalues) });
    }


    //Find products implementing this optionvalue
    const filtersetting = filtersettingids.sort((lhs, rhs) => lhs - rhs).join(",");
    return { filtersetting, optionfilters };
  }

  /** Check if we need to reapply filters */
  async checkFilters() {
    const filters = this.getCurrentFilters();
    const isfirstcheck = this.lastfiltersetting === null;

    if (!isfirstcheck && this.lastfiltersetting === filters?.filtersetting)
      return; //no changes

    //Update the hash
    this.lastfiltersetting = filters.filtersetting;
    if (!isfirstcheck && this.hashvar) {
      const hashparams = new URLSearchParams(location.hash.substring(1));
      if (!this.lastfiltersetting)
        hashparams.delete(this.hashvar);
      else
        hashparams.set(this.hashvar, this.lastfiltersetting);

      location.replace("#" + hashparams.toString());
    }

    //Update the products (unless this is the first render and nothing is filtered - just leave everything alone then)
    if (!isfirstcheck || filters.filtersetting)
      this.pricehandler.setProductFilter(filters);
  }
}
