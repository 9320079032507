import * as finmath from '@mod-system/js/util/finmath';
import { roundMoney } from '../internal/webshopmath';
import { WebShopNumDecimals } from '../internal/types';

interface DiscountAmount {
  percentage?: number;
  fixed?: finmath.FinmathInput;
  roundpricetowhole?: boolean;
}

interface Discount {
  conditions: {
    minitemcount: number;
    minproducttotal: finmath.FinmathInput;
  };
  products: number[];
  requiredproducts: number[];
  hasrequiredproducts: boolean;
  type: "quantity" | "promotion" | "singleusecode" | "multiusecode";
  limited: boolean;
  code: string;
  slogan: string;
  wrd_title: string;
  discount: DiscountAmount;
}

interface Promotion extends Discount {
  value: finmath.FinmathInput;
  active: boolean;
}

function applyDiscounts({ product, amount, price, discounts, cartproductids, producttotal, filter, keepinactive }: {
  product: number;
  amount: number;
  price: finmath.FinmathInput;
  discounts: Discount[];
  cartproductids: number[];
  producttotal: finmath.FinmathInput;
  filter: (discount: Discount) => boolean;
  keepinactive?: (discount: Discount) => boolean;
}) {
  let promotions = new Array<Promotion>;
  const originalprice = price;

  let promotionprice = price;
  let bestpromotion = null;

  for (const discount of discounts) {
    if (!filter(discount))
      continue;

    let isactive = (discount.conditions.minitemcount ? amount >= discount.conditions.minitemcount : true)
      && (discount.conditions.minproducttotal ? finmath.test(producttotal, '>=', discount.conditions.minproducttotal) : true);

    if (isactive && discount.limited && discount.products) { // per-product promotion discounts don't have product lists

      if (!product || !discount.products.includes(product))
        isactive = false;
    }

    if (isactive && discount.hasrequiredproducts) {
      // at least one required product should be ordered
      if (!cartproductids.some(id => discount.requiredproducts.includes(id)))
        isactive = false;
    }

    const discountprice = finmath.subtract(originalprice, calculateDiscount(originalprice, discount.discount));

    // A 0-worth discount (eg free tv or so) is the best if there is no other discount active
    const isnewbest: boolean = isactive && (bestpromotion
      ? finmath.cmp(discountprice, promotionprice) < 0
      : finmath.cmp(discountprice, promotionprice) <= 0);

    const promotionrec: Promotion = { ...discount, value: finmath.multiply(discount.limited ? amount : 1, finmath.subtract(originalprice, discountprice)), active: isnewbest };

    if (!isactive && (!keepinactive || !keepinactive(promotionrec)))
      continue;

    promotions.push(promotionrec);
    if (isnewbest) {
      if (bestpromotion)
        bestpromotion.active = false;
      bestpromotion = promotionrec;
      promotionprice = discountprice;
    }
  }

  // active last, then sort on increasing value
  promotions.sort((a, b) => a.active ? 1 : b.active ? -1 : finmath.cmp(a.value, b.value));

  // deduplicate by title. remove inactive versions of active titles
  const activetitles = new Set(promotions.filter(_ => _.active).map(_ => _.wrd_title));
  promotions = promotions.filter(_ => _.active || !activetitles.has(_.wrd_title));

  return { promotionprice, promotions };
}

export function applyProductQuantityDiscounts({ product = 0, amount, price, discounts }: {
  product: number;
  amount: number;
  price: string; //FinmathInput
  discounts: Discount[];
}) {
  const res = applyDiscounts(
    {
      product,
      amount,
      price,
      discounts,
      cartproductids: [],
      producttotal: 0,
      filter: discount => discount.type === "quantity"
    });

  return res.promotionprice;
}

export function applyProductPromotionDiscounts({ product, amount, price, discounts, cartproductids, producttotal, couponcodes = [] }: {
  product: number;
  amount: number;
  price: string; //FinmathInput
  discounts: Discount[];
  cartproductids: number[];
  producttotal: string; //FinmathInput
  couponcodes: string[];
}) {
  return applyDiscounts(
    {
      product,
      amount,
      price,
      discounts,
      cartproductids,
      producttotal,
      filter: discount => {
        if (!discount.limited)
          return false;
        if (discount.type === "promotion")
          return true;
        if (discount.type === "singleusecode" || discount.type === "multiusecode")
          return couponcodes.includes(discount.code);
        return false;
      }
    });
}

export function applyOrderDiscounts({ ordertotal, discounts, cartproductids, producttotal, couponcodes = [] }: {
  product: number;
  amount: number;
  price: string; //FinmathInput
  discounts: Discount[];
  ordertotal: finmath.FinmathInput;
  cartproductids: number[];
  producttotal: string; //FinmathInput
  couponcodes: string[];
}) {
  return applyDiscounts(
    {
      product: 0,
      amount: 1,
      price: ordertotal,
      discounts,
      cartproductids,
      producttotal,
      filter: discount => {
        if (discount.limited)
          return false;
        if (discount.type === "promotion")
          return true;
        if (discount.type === "singleusecode" || discount.type === "multiusecode")
          return couponcodes.includes(discount.code);
        return false;
      },
      keepinactive: (discount: Discount) => discount.type === "singleusecode" || discount.type === "multiusecode"
    });
}

function calculateDiscount(price: finmath.FinmathInput, discount: DiscountAmount): finmath.FinmathInput {
  const percentage = (discount.percentage && finmath.cmp(discount.percentage, 0) !== 0 && discount.percentage) || 0;
  const fixed = (discount.fixed && finmath.cmp(discount.fixed, 0) !== 0 && discount.fixed) || 0;

  let reduction = finmath.add(finmath.getPercentageOfAmount(price, percentage), fixed);

  // If roundpricetowhole is TRUE, round the price after discount down to whole numbers
  if (discount.roundpricetowhole)
    reduction = finmath.subtract(price, finmath.roundToMultiple(finmath.subtract(price, reduction), 1, "down"));
  else
    reduction = roundMoney(reduction, "up", WebShopNumDecimals);

  //  if( (price < 0 AND reduction > 0) or (price > 0 AND reduction < 0))
  //    THROW NEW Exception("Discount not understood");

  // Don't discount below 0
  if (finmath.cmp(finmath.subtract(price, reduction), 0) < 0) {
    return finmath.cmp(0, price) < 0 // return max(0, price)
      ? price
      : "0";
  }

  return reduction;
}
