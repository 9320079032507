/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import RPCFormBase from '@mod-publisher/js/forms/rpc';

export default class RequestPaymentWidget extends RPCFormBase {
  constructor(webshop, node, options) {
    super(node);
  }
}
