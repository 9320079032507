import "./slider.scss"
import * as dompack from "dompack";

/*
  Public
    Functions:
      refresh
      setValues
      getValues
      getValue
    Events:
      sliderstart
      slidermove
      sliderstop
      sliderchange

  css: .wh-slider-holder > .wh-slider > .wh-slider-knob
                                      > .wh-slider-rangebar (optional)

  Example html:
  <div id="sliders_holder" class="wh-slider-holder">
    <div class="wh-slider"><div class="wh-slider-rangebar"></div><div class="wh-slider-knob drag1"></div><div class="wh-slider-knob drag2"></div></div>
  </div>
*/

export default class cSlider
{
  constructor(node, options)
  {
     this.options = { minvalue         : 0
                    , maxvalue         : 100
                    , startvalues      : [50]
                    , snap             : 1     //snap interval, 0:nosnapping
                    };

    this.node = node;

    this.slidebasenode = this.node.querySelector('.wh-slider');
    if(!this.slidebasenode)
    {
      console.log('Wrong selector, no class wh-slider found');
      return false;
    }

    window.addEventListener("load", ev => this.refresh() );

    if( options.minvalue )
      this.options.minvalue = options.minvalue;
    if( options.maxvalue )
      this.options.maxvalue = options.maxvalue;
    if( options.startvalues )
      this.options.startvalues = options.startvalues;

    this.isvertical = this.node.classList.contains('vertical') || this.slidebasenode.classList.contains('vertical');
    this.size       = this.getNodeSize( this.slidebasenode );
    this.scale = (this.options.maxvalue - this.options.minvalue) / (this.isvertical ? this.size.y : this.size.x);

    this.values = [];
    this.activeknob = null;
    this.dragmovefn = this.onDragMove.bind(this);
    this.dragendfn = this.onDragEnd.bind(this);
    this.dragidx = -1;
    this.knobs = [];

    this.touchenable = "ontouchstart" in window || "createTouch" in document || "TouchEvent" in window ||
                       (window.DocumentTouch && document instanceof window.DocumentTouch) ||
                       navigator.maxTouchPoints > 0 ||
                       window.navigator.msMaxTouchPoints > 0;

    //slider can have multiple knobs
    let minvalue = null;
    let i = 0;
    for( let dragnode of this.slidebasenode.querySelectorAll('.wh-slider-knob') )
    {
      this.knobs.push(dragnode);

      dragnode.wh_dragpos = 0;

      let startvalue = 0;
      if(i < this.options.startvalues.length)
        startvalue = this.options.startvalues[i];

      if(startvalue < this.options.minvalue)
        startvalue = this.options.minvalue;

      if(startvalue > this.options.maxvalue)
        startvalue = this.options.maxvalue;

      if(this.options.snap > 0)
        startvalue = this.calcSnapValue( startvalue );

      if( dragnode.children.length && dragnode.children[0].nodeName == "SPAN")
        dragnode.children[0].textContent = startvalue;

      this.values.push( startvalue );

      if( i == 0 || startvalue < minvalue )
        minvalue = startvalue;

      dragnode.wh_value = startvalue;
      dragnode.wh_dragpos = Math.round((startvalue - this.options.minvalue)/this.scale);

      if(this.isvertical)
        dragnode.style.top = dragnode.wh_dragpos + 'px';
      else
        dragnode.style.left = dragnode.wh_dragpos + 'px';

      dragnode.wh_dragger = {};

      let idx = i;
      dragnode.addEventListener("mousedown", ev => this.onDragStart(dragnode, ev, idx) );
      if( this.touchenable )
        dragnode.addEventListener("touchstart", ev => this.onDragStart(dragnode, ev, idx) );

      ++i;
    }

    this.rangebar = this.slidebasenode.querySelector('.wh-slider-rangebar');
    if(this.rangebar)
    {
      this.rangebar.wh_value = minvalue;
      this.rangebar.wh_dragpos = Math.round(minvalue/this.scale);

      if(this.values.length > 1)
      { //make draggable if it's a rangebar between draggers
        this.rangebar.wh_dragger = {};
        this.rangebar.addEventListener("mousedown", ev => this.onDragStart(this.rangebar, ev, -1) );
        if( this.touchenable )
          this.rangebar.addEventListener("touchstart", ev => this.onDragStart(this.rangebar, ev, -1) );
      }

      this.updateRangebarPosition(this.values);
    }

  }

  getNodeSize(node)
  {
    //FIXME: size of hidden nodes
    return {x : node.clientWidth, y : node.clientHeight};
  }

  calcSnapValue(value)
  {
    let precision = this.options.snap > 0 ? this.log10(this.options.snap) : 0;
    if(precision <= 0)
      value = 1*(Number(value).toFixed(precision));//FIXME
    else
    {
      let f = value - ~~(value / this.options.snap)*this.options.snap;
      if(f > 0)
      {
        value = ~~(value / this.options.snap)*this.options.snap;
        if(f >= this.options.snap*0.5)
          value += this.options.snap;
      }
      value = Math.round(value);
    }

    return value;
  }

  log10(val)
  { //IE doesn't support Math.log10
    return Math.log(val) / Math.log(10);
  }

  //Internal
  onDragStart( dragnode, event, idx )
  {
    if( this.options.readonly || this.activeknob )
      return;

    let pageX = event.touches ? event.touches[0].pageX : event.pageX;
    let pageY = event.touches ? event.touches[0].pageY : event.pageY;

    this.dragidx = idx;
    this.activeknob = dragnode;

    this.node.addEventListener("mousemove", this.dragmovefn );
    document.body.addEventListener("mouseup", this.dragendfn );
    if( this.touchenable )
    {
      this.node.addEventListener("touchmove", this.dragmovefn );
      document.body.addEventListener("touchend", this.dragendfn );
    }


    //get/set intial/start position
    dragnode.wh_dragger.startscroll = { "x" : pageX, "y" : pageY };

    if(this.isvertical)
      dragnode.wh_dragger.startscroll.y -= dragnode.wh_dragpos;
    else
      dragnode.wh_dragger.startscroll.x -= dragnode.wh_dragpos;

    dragnode.style.zIndex = "1";

    let pos = this.calcDragInfo({ "x" : pageX, "y" : pageY }, dragnode);
    this.value = pos.snapvalue;

    dompack.dispatchCustomEvent(this.node, "sliderstart", { bubbles    : true
                                                          , cancelable : false
                                                          , detail     : { target : this.node
                                                                         , knob   : dragnode
                                                                         , value  : this.value
                                                                         }
                                                          });
  }

  //Internal
  onDragEnd(event)
  {
    if(this.options.readonly)
      return;

    if(this.options.snap > 0)
    {
      this.values = this.getValues();
      this.setValues(this.values);//set correct snap position
    }
    this.activeknob.style.zIndex = "";

    this.node.removeEventListener("mousemove", this.dragmovefn );
    document.body.removeEventListener("mouseup", this.dragendfn );
    if( this.touchenable )
    {
      this.node.removeEventListener("touchmove", this.dragmovefn );
      document.body.removeEventListener("touchend", this.dragendfn );
    }

    dompack.dispatchCustomEvent(this.node, "sliderstop", { bubbles    : true
                                                         , cancelable : false
                                                         , detail     : { target : this.node
                                                                        , knob   : this.activeknob
                                                                        , value  : this.value
                                                                        }
                                                         });

    this.activeknob = null;
    this.dragidx = -1;
  }

  //Internal
  onDragMove( event /* dragnode,i,event */)
  {
    if(this.options.readonly)
      return;

    event.preventDefault();

    let i = this.dragidx;

    let pageX = event.touches ? event.touches[0].pageX : event.pageX;
    let pageY = event.touches ? event.touches[0].pageY : event.pageY;

    let changed = false;
    if(i < 0)
    {//dragging rangebar
      let minvalue = this.values[0];
      let maxvalue = this.values[0];
      for(let i=0;i < this.values.length; i++)
      {//determin min.max value
        if(this.values[i] < minvalue)
          minvalue = this.values[i];
        else if(this.values[i] > maxvalue)
          maxvalue = this.values[i];
      }

      let pos = this.calcDragInfo({ "x" : pageX, "y" : pageY }, this.activeknob);
      this.activeknob.wh_dragpos = pos.px;

      this.value = pos.snapvalue;

      // knob with minvalue corresponds with position rangebar
      let delta = this.value - minvalue;
      if(delta + minvalue < this.options.minvalue)
        delta = this.options.minvalue - minvalue;
      else if(delta + maxvalue > this.options.maxvalue)
        delta = this.options.maxvalue - maxvalue;

      let newvalues = [];
      let oldvalues = this.getValues();
      for(let i=0;i < this.values.length; i++)
      {
        let val = this.calcSnapValue(this.values[i] + delta);
        newvalues.push(val);
        if(!changed)
          changed = oldvalues.indexOf(val) == -1;
      }

      this.setValues(newvalues,true);//update knob and rangebar positions
    }
    else
    {//dragging a knob
      let pos = this.calcDragInfo({ "x" : pageX, "y" : pageY },this.activeknob);

      if(this.value!=null)
        changed = pos.snapvalue != this.value;

      this.updateKnobPosition(pos,this.activeknob);
      this.value = this.options.snap > 0 ? pos.snapvalue : pos.value;
      this.activeknob.wh_value = this.value;
      this.values[i] = this.value;

      if( this.activeknob.children.length && this.activeknob.children[0].nodeName == "SPAN")
        this.activeknob.children[0].textContent = this.value;

      if(this.rangebar)
        this.updateRangebarPosition();
    }

    if(changed)
    {
      dompack.dispatchCustomEvent(this.node, "sliderchange", { bubbles    : true
                                                             , cancelable : false
                                                             , detail     : { target : this.node
                                                                            , values : this.values
                                                                            , knob   : i < 0 ? this.knobs[0] : this.knobs[i]
                                                                            , value  : this.value
                                                                            }
                                                             });
    }

    dompack.dispatchCustomEvent(this.node, "slidermove", { bubbles    : true
                                                         , cancelable : false
                                                         , detail     : { target : this.node }
                                                         });
  }

  calcDragInfo(dragpos, dragnode)
  {
    let dragvalues = {px : dragnode.wh_dragpos, value : null, snapvalue : null};
    if(this.isvertical)
    {
      dragvalues.px =(dragpos.y - dragnode.wh_dragger.startscroll.y);
      if(dragvalues.px > this.size.y)
        dragvalues.px = this.size.y;
      if(dragvalues.px < 0)
        dragvalues.px = 0;
    }
    else
    {
      dragvalues.px = (dragpos.x - dragnode.wh_dragger.startscroll.x);
      if(dragvalues.px > this.size.x)
        dragvalues.px = this.size.x;
      if(dragvalues.px < 0)
        dragvalues.px = 0;
    }

    dragvalues.value = dragvalues.px*this.scale + this.options.minvalue;

    if(dragvalues.value < this.options.minvalue)
      dragvalues.value = this.options.minvalue;
    else if(dragvalues.value > this.options.maxvalue)
      dragvalues.value = this.options.maxvalue;

    if(this.options.snap > 0)
      dragvalues.snapvalue = this.calcSnapValue(dragvalues.value);
    else
      dragvalues.snapvalue = dragvalues.value;

    return dragvalues;
  }

  //Public:
  getValue()
  {
    return (this.options.snap > 0 ? this.calcSnapValue(this.value) : this.value);
  }

  //Public:
  getValues()
  {
    let values = this.values;

    if(this.options.snap > 0)
    {
      for(let i = 0; i < this.values.length; i++)
        values[i] = this.calcSnapValue(values[i]);
    }

    return values;
  }

  //Public: Override intial/current dragger values
  setValues(values, nosnap)
  {
    if(typeof values == 'object')
    {
      for(let c=0; c < values.length && c < this.values.length; c++)
        this.values[c] = values[c];
    }
    else if(this.values.length)
    {
      this.values[0] = values;
    }

    for(let i=0; i < this.values.length; i++)
    {
      if(this.values[i] < this.options.minvalue)
        this.values[i] = this.options.minvalue;
      else if(this.values[i] > this.options.maxvalue)
        this.values[i] = this.options.maxvalue;
    }

    this.scale = (this.options.maxvalue - this.options.minvalue) / (this.isvertical ? this.size.y : this.size.x);

    let rangebarvalues = this.values;
    let i = 0;
    for( let dragnode of this.slidebasenode.querySelectorAll(".wh-slider-knob") )
    {
      let snapvalue = this.values[i];
      if(this.options.snap > 0)
      {
        snapvalue = this.calcSnapValue(this.values[i]);
        rangebarvalues[i] = !nosnap ? snapvalue : this.values[i];
      }

      if( dragnode.children.length && dragnode.children[0].nodeName == "SPAN")
        dragnode.children[0].textContent = snapvalue;

      dragnode.wh_value   = snapvalue;
      dragnode.wh_dragpos = Math.round((dragnode.wh_value - this.options.minvalue)/this.scale);

      if(this.isvertical)
        dragnode.style.top = dragnode.wh_dragpos + 'px';
      else
        dragnode.style.left = dragnode.wh_dragpos + 'px';

      ++i;
    }

    if(this.rangebar)
      this.updateRangebarPosition(this.values);
  }

  //Internal
  updateKnobPosition(pos,dragnode)
  {
    dragnode.wh_dragpos = pos.px;

    if(this.isvertical)
      dragnode.style.top = dragnode.wh_dragpos + 'px';
    else
      dragnode.style.left = dragnode.wh_dragpos + 'px';
  }

  //Internal
  updateRangebarPosition()
  {
    let rangemin = this.values.length > 1 ? this.values[0] : this.options.minvalue;
    let rangemax = this.values[0];

    for(let i=1; i < this.values.length; i++)
    {
      if(this.values[i] < rangemin)
        rangemin = this.values[i];
      else if(this.values[i] > rangemax)
        rangemax = this.values[i];
    }

    let rangepos  = ~~((rangemin - this.options.minvalue)/this.scale);
    let rangesize = ~~((rangemax - rangemin)/this.scale);

    this.rangebar.wh_value   = rangemin;
    this.rangebar.wh_dragpos = rangepos;

    if(this.isvertical)
    {
      this.rangebar.style.top =  rangepos +'px';
      this.rangebar.style.height =  rangesize +'px';
    }
    else
    {
      this.rangebar.style.left =  rangepos +'px';
      this.rangebar.style.width =  rangesize +'px';
    }
  }

  updateOptions(opts)
  {
    const orgoptions = { ...this.options };
    this.options = { ...this.options, ...opts };

    const newvals = [ ...this.values ];

    // clip the values
    for(let i=0; i < newvals.length; i++)
    {
      if(newvals[i] < this.options.minvalue || newvals[i] === orgoptions.minvalue)
        newvals[i] = this.options.minvalue;
      else if(newvals[i] > this.options.maxvalue || newvals[i] === orgoptions.maxvalue)
        newvals[i] = this.options.maxvalue;
    }

    this.refresh();
    this.setValues(newvals);
  }

  //Public: use refresh if size of slider has changed
  refresh()
  {
    this.size = this.getNodeSize(this.slidebasenode);
    this.scale = (this.options.maxvalue - this.options.minvalue) / (this.isvertical ? this.size.y : this.size.x);

    let i = 0;
    for( let dragnode of this.slidebasenode.querySelectorAll('.wh-slider-knob') )
    {
      dragnode.wh_dragpos = 0;

      if(i == 0)
        this.scale = (this.options.maxvalue - this.options.minvalue) / (this.isvertical ? this.size.y : this.size.x);

      dragnode.wh_dragpos = Math.round((this.values[i] - this.options.minvalue)/this.scale);

      if(this.isvertical)
        dragnode.style.top = dragnode.wh_dragpos + 'px';
      else
        dragnode.style.left = dragnode.wh_dragpos + 'px';

      if(this.rangebar && this.values.length > 1)
        this.updateRangebarPosition(this.values);

      ++i;
    }
  }
}
