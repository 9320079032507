import * as dompack from 'dompack';

function ShowReviewStats()
{
  let node = document.getElementById("rating-stats");
  if( node )
    node.classList.toggle("show");
}

dompack.register("#showreviewstats", node => {
  node.addEventListener("click", ev => ShowReviewStats());
});
