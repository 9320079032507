// Auto-generated RPC interface from /opt/whdata/installedmodules/webshop.20231004T072211.581Z/js/internal/api.rpc.json
var RPCClient = require("@mod-system/js/wh/rpc").default;
var request = exports.rpcclient = new RPCClient("webshop:shop");
exports.rpcResolve = function (promise, result) { request._handleLegacyRPCResolve(promise, result) };
exports.invoke = function () { return request.invoke.apply(request,Array.prototype.slice.call(arguments)); }

Object.defineProperty(module.exports, "HTTP_ERROR", { get: function () { return JSONRPC.HTTP_ERROR; }});
Object.defineProperty(module.exports, "JSON_ERROR", { get: function () { return JSONRPC.JSON_ERROR; }});
Object.defineProperty(module.exports, "PROTOCOL_ERROR", { get: function () { return JSONRPC.PROTOCOL_ERROR; }});
Object.defineProperty(module.exports, "RPC_ERROR", { get: function () { return JSONRPC.RPC_ERROR; }});
Object.defineProperty(module.exports, "OFFLINE_ERROR", { get: function () { return JSONRPC.OFFLINE_ERROR; }});
Object.defineProperty(module.exports, "TIMEOUT_ERROR", { get: function () { return JSONRPC.TIMEOUT_ERROR; }});
Object.defineProperty(module.exports, "SERVER_ERROR", { get: function () { return JSONRPC.SERVER_ERROR; }});

// Adding dependency: '/opt/whdata/installedmodules/webshop.20231004T072211.581Z/lib/internal/frontend/shopservice.whlib'

exports.describeCart = exports.DescribeCart = /*RECORD*/function (/*RECORD*/ webshopptr, /*RECORD*/ cart)
{
return request.invoke.apply(request,["DescribeCart"].concat(Array.prototype.slice.call(arguments)));
}

exports.retryPayment = exports.RetryPayment = /*RECORD*/function (/*RECORD*/ webshopptr, /*STRING*/ retrydata)
{
return request.invoke.apply(request,["RetryPayment"].concat(Array.prototype.slice.call(arguments)));
}

exports.selectOptionsByFilter = exports.SelectOptionsByFilter = /*RECORD*/function (/*RECORD*/ webshopptr, /*RECORD ARRAY*/ products, /*RECORD*/ options)
{
return request.invoke.apply(request,["SelectOptionsByFilter"].concat(Array.prototype.slice.call(arguments)));
}

exports.getProductEnabledOptions = exports.GetProductEnabledOptions = /*RECORD*/function (/*RECORD*/ webshopptr, /*INTEGER*/ product, /*RECORD*/ options)
{
return request.invoke.apply(request,["GetProductEnabledOptions"].concat(Array.prototype.slice.call(arguments)));
}
