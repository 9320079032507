import * as popperjs from '@popperjs/core';
import * as dompack from 'dompack';
import Glide from '@glidejs/glide';
import '@glidejs/glide/dist/css/glide.core.min.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';
import 'lazysizes';

var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))

var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) 
{
  return new Tooltip(tooltipTriggerEl)
})

// toggle ul in sidemenu
document.querySelectorAll('.has-submenu > .submenu-toggle').forEach(item => {
  item.addEventListener('click', function () 
  {
    this.nextElementSibling.classList.toggle('d-block');
    this.classList.toggle('collapse--open');
    this.parentNode.parentNode.classList.remove('collapse--open');
  });
});

dompack.onDomReady(() =>
{
    // product slider widget
    var productslider = document.querySelector('.products-slider  .webshop-products__item');

    if (typeof (productslider) != 'undefined' && productslider !== null)
    {


      new Glide('.products-slider', 
      {
        type: 'carousel',
        perView: 5,
        gap: 20,
        autoplay: 3000,
        breakpoints: 
        {
          1400: {
            perView: 5
          },
          992: {
            perView: 4
          },
          768: {
            perView: 2
          },
          480: {
            perView: 1.5
          }
        }
      }).mount()
    }

    uspCycle();

    setInterval(function()
    {
       uspCycle(); 
    }, 8000);

});

function uspCycle()
{
    var elements = document.querySelectorAll('.usp_item')
    
    if(!elements)
        return;

    if(!window.matchMedia("(max-width:992px)").matches)
        return;

    for (let i = 0; i < elements.length; i++) 
    {
      setTimeout(function() 
      {
        elements[i].style.opacity = 1;
      }, 2000 * i);
      if (i < (elements.length)) 
      {
        setTimeout(function() 
        {
          elements[i].style.opacity = 0;
        }, 2000 * (i + 1));
      }
    }
}