//x@ts-nocheck -- converted from ES

import * as finmath from '@mod-system/js/util/finmath';
import * as dompack from 'dompack';
import { frontendConfig } from '@webhare/frontend';
import * as dialogapi from 'dompack/api/dialog';
import * as offerteform from '@mod-forshops/shopextensions/offerteform/frontend/offerteform';
import { Product, ProductTypeHandler } from "@mod-webshop/js/shopservice/product";
import { UpdatedPricesEvent } from '@mod-webshop/js/shopservice';

function getPiecePrice(prices: QuantityPrice[], quantity: number): string {
  if (prices.length == 0)
    return '0';

  return prices.filter(_ => quantity >= _.quantity).at(-1)?.price ?? '0';//prices[0].price;
}

export interface PriceMatrix {
  options: number[];
  prices: QuantityPrice[];
  size: string;
  sku: string;
}

export interface QuantityPrice {
  price: string;
  quantity: number;
}

export interface PromidataSelectedConfiguration {
  items?: Array<{
    size: string;
    quantity: number;
  }>;
  imprints?: Array<{
    position: string;
    option: string;
  }>;
}

interface ImprintPosition {
  code: string;
  title: string;
  imprintoptions: Array<{
    sku: string;
    title: string;
    onrequest?: boolean;
    imprintcosts: Array<{
      sku: string;
      title: string;
      prices: Array<{
        quantity: number;
        price: string;
        onrequest?: boolean;
      }>;
    }>;
    prices: Array<{
      quantity: number;
      price: string;
    }>;

  }>;
}

export interface PromidataProductInfo {
  imprints: Array<{
    options: number[];
    imprintpositions: ImprintPosition[];
  }>;

  minimumpersize: number;
  pricematrix: PriceMatrix[];
  requireminimums: boolean;
  sizes: string[];
  countallsizes: boolean;
}


interface PromidataConfigurationCostLine {
  sku: string;
  title: string;
  costs: string;
  costsperpiece: string;
  onrequest: boolean;
}

export interface PromidataConfiguration {
  totalquantity: number;
  costs: PromidataConfigurationCostLine[];
  imprintselection: Array<{
    position: string;
    option: string;
    positiontitle: string;
    optiontitle: string;
  }>;
  itemselection: Array<{
    quantity: number;
    size: string;
    sku: string;
  }>;
  validorder: boolean;
  requiredminimum: number | null;
  anyonrequest: boolean;
  finalprice: string;
  minimumpersize: number | null;
}

/* use PromidataProduct as base class for promidatashops

*/
export class PromidataProduct extends ProductTypeHandler {
  minimumprice: number;
  productinfo: PromidataProductInfo;
  _currentquantity: number = 0;
  _lastselection: string | undefined;


  constructor(node: HTMLElement, product: Product, initialhashparams: URLSearchParams, options?: { minimumprice?: number }) {
    super(node, product, initialhashparams);
    this.minimumprice = options?.minimumprice || 0;

    if (frontendConfig.obj.promidata_debug) {
      const debuginfo = frontendConfig.obj.promidata_debug as {
        shoptag: string;
        promidata_producturl: string;
        //TODO there is much more...
      };

      const updatecmd = `wh ${debuginfo.shoptag.split(':')[0]}:refresh_promidata_one ${debuginfo.promidata_producturl}`;
      console.log(`*** Promidata product ***\n\nTo update this product:\n${updatecmd}\n\nObject dump: %o\n\n`, debuginfo);
    }

    this.productinfo = frontendConfig.obj.promidataproduct as PromidataProductInfo;
    this._checkPriceTablesUpdate();

    if (initialhashparams.get("config")) //we need the imprintselectors from this._checkPriceTablesUpdate
      this._applyConfig(initialhashparams.get("config")!);

    this.product.oncalculateconfiguration = () => this.calculateConfiguration();
    this.product.oncheckbeforeadd = () => this.checkOrderConfigBeforeAdd();

    for (const inputcontrol of this.node.querySelectorAll<HTMLInputElement>('input.promidata--inputamount')) {
      inputcontrol.addEventListener("input", evt => this.checkSizeInput(evt, inputcontrol));
    }

    addEventListener("webshop:updatedprices", evt => this._updateOurPriceInfo(evt));

    //separate form based approach, disabled
    // for(let quoterequestbutton of this.node.querySelectorAll(".promidata--js-quoterequest"))
    //   quoterequestbutton.addEventListener("click", evt => this._doQuoteRequest(evt));

  }

  _applyConfig(configstr: string) {
    try {
      const config = JSON.parse(configstr) as PromidataSelectedConfiguration;
      for (const item of config?.items || []) {
        const input = this.getInputForSize(item.size);
        if (input)
          input.value = String(item.quantity);
      }

      if (config?.imprints?.[0]) {
        const free_imprint_selector = dompack.qSA<HTMLSelectElement>(this.node, ".promidata-imprintposition__pulldown")[0];
        if (free_imprint_selector) {
          dompack.changeValue(free_imprint_selector, config.imprints[0].position);

          const matching_imprint_option_selector = free_imprint_selector.closest('.promidata-imprint')?.querySelector<HTMLSelectElement>('.promidata-imprintoption__pulldown');
          if (matching_imprint_option_selector)
            dompack.changeValue(matching_imprint_option_selector, config.imprints[0].option);
        }
      }
    } catch (e) {
      console.error("Error applying config", e);
    }
  }

  // separate form based approach
  // _doQuoteRequest_FORMPAGE(evt)
  // {
  //   dompack.stop(evt);
  //   dompack.flagUIBusy({ismodal: true});
  //   whintegration.executeSubmitInstruction({ type: "form"
  //                                          , form: { method: "POST"
  //                                                  , action: frontendConfig.obj.requestquoteform
  //                                                  , vars: [ { name: "product"
  //                                                            , value: this.product.getProductTitle()
  //                                                            }
  //                                                          , { name: "orderdetails"
  //                                                            , value: this._orderdetails
  //                                                            }
  //                                                          ]
  //                                                  }
  //                                          });
  // }

  _updateOurPriceInfo(evt: UpdatedPricesEvent) {
    let perpiece = evt.detail.currentprice;
    if (perpiece !== "onrequest" && this._currentquantity && this._currentquantity > 0)
      perpiece = (finmath.divide || finmath.moneyDivide)(perpiece, this._currentquantity);

    for (const pricenode of this.node.querySelectorAll('.promidata--priceperpiece'))
      pricenode.textContent = perpiece === "onrequest" ? "" : finmath.formatPrice(finmath.roundToMultiple(perpiece, "0.01", "up"), ",", 2);
  }

  checkSizeInput(evt: Event, inputcontrol: HTMLInputElement) {
    if (inputcontrol.value.length > 4) //block silly things such as typing a lot of numbers as the browse doesn't
      inputcontrol.value = inputcontrol.value.substring(0, 4);

    this.product.updatePrice();
  }

  getInputForSize(sizecode: string | null): HTMLSelectElement | HTMLInputElement | null {
    if (!sizecode) //get the standard amount field
      return this.node.querySelector<HTMLSelectElement | HTMLInputElement>(`[name=promidata_orderamount]`);

    //look up the spinner for this size
    return this.node.querySelector<HTMLSelectElement | HTMLInputElement>(`[name="promidata_orderamount_${CSS.escape(sizecode)}"]`);
  }

  getQuantityForSize(sizecode: string) {
    return parseInt(this.getInputForSize(sizecode)?.value || "") || 0;
  }

  /** Return true if this order satisfies configuration and quantity demands.  */
  checkOrderConfigBeforeAdd() {
    const res = this._describeCurrentConfiguration('0');
    if (!res)
      return false;
    if (res.totalquantity <= 0) {
      dialogapi.runMessageBox("U heeft nog geen aantal gekozen", [{ title: "OK" }], {
        allowcancel: true
      });

      return false;
    }

    if (!this.checkValidQuoteConfiguration().valid)
      return false;

    return true;
  }

  checkValidQuoteConfiguration() {
    if (this.productinfo.requireminimums) {
      const res = this._describeCurrentConfiguration('0');
      if (res && !res.validorder)
        return { valid: false, requiredminimum: res.requiredminimum, minimumpersize: res.minimumpersize };
    }

    return { valid: true };
  }

  _checkPriceTablesUpdate() {
    const selectedoptions = this._getCurrentOptions();
    const selectedoptions_str = JSON.stringify(selectedoptions);
    if (selectedoptions_str == this._lastselection)
      return;

    this._lastselection = selectedoptions_str;
    const rawprices = this.productinfo.pricematrix.filter(row => JSON.stringify(row.options) == selectedoptions_str);
    const rawimprints: ImprintPosition[] = this.productinfo.imprints.find(row => JSON.stringify(row.options) == selectedoptions_str)?.imprintpositions ?? [];
    const rawsizes = (this.productinfo.sizes.length ? this.productinfo.sizes : [null]);

    //assume the first price's quantity-cutoff points will apply to all other prices
    const quantities = rawprices[0]?.prices.map(_ => _.quantity) ?? [];
    const baseprices = [], imprints = [];

    for (const size of rawsizes) {
      const rawpriceinfo = size ? rawprices.find(row => row.size == size) : rawprices[0];

      //Hide an input control if it's not in the size list
      const controlholder = this.getInputForSize(size)!.closest<HTMLDivElement>('.promidata__orderamountcontrol');
      if (controlholder)
        controlholder.hidden = !rawpriceinfo;

      if (!rawpriceinfo) {
        //Reset invisible controls skip them for calculation updats
        this.getInputForSize(size)!.value = '';
        continue;
      }

      const row = {
        size: size || '',
        perpiece: quantities.map(quantity => getPiecePrice(rawpriceinfo.prices, quantity))
      };

      baseprices.push(row);
    }

    //TODO size-specific? base prices? minimum quantites for this action
    for (const imprintposition of rawimprints) {
      const row = {
        title: imprintposition.title,
        options: imprintposition.imprintoptions.map(option =>
        ({
          title: option.title,
          perpiece: quantities.map(quantity => finmath.add(getPiecePrice(rawprices[0].prices, quantity)
            , getPiecePrice(option.prices, quantity))
          )
        }))
      };

      imprints.push(row);
    }

    this._setupImprintSelection(rawimprints);

    dompack.dispatchCustomEvent(this.node
      , "forshops:promidata-priceinfo"
      , {
        bubbles: true,
        cancelable: false,
        detail: { rawprices, rawimprints, baseprices, imprints, quantities, sizes: this.productinfo.sizes, countallsizes: this.productinfo.countallsizes}
      });

  }

  _setupImprintSelection(imprints: ImprintPosition[]) {
    const injectinto = this.node.querySelector('.promidata--js-injectprintoptions');
    if (!injectinto)
      return void console.error("cannot find promidata--js-injectprintoptions to load any imprint options");

    if (!imprints.length) {
      injectinto.replaceChildren();
      return;
    }

    const imprintpositionpulldown = <select class="promidata-imprintposition__pulldown form-select mb-3">
      <option value="noimprint" selected>Zonder bewerking</option>
      {imprints.map(position => <option value={position.code}>{position.title}</option>)}
    </select>;

    const imprintholder = <div class="promidata-imprint">
      <div class="promidata-imprintposition mb-3">
        <label class="form-label">
          Bewerking
        </label>
        {imprintpositionpulldown}
      </div>
      <div class="promidata-imprintoption">
      </div>
    </div>;

    imprintpositionpulldown.addEventListener("change", () => this._onImprintPositionChange(imprintpositionpulldown, imprints));
    injectinto.replaceChildren(imprintholder);
  }

  //invoked on any change to the position pulldown
  _onImprintPositionChange(pulldown: HTMLSelectElement, imprints: ImprintPosition[]) {
    //find the area containing this imprint option (prepares for adding more than one)
    const optionarea = pulldown.closest(".promidata-imprint")!.querySelector(".promidata-imprintoption")!;
    const position = imprints.find(imprint => imprint.code == pulldown.value);

    if (!position) { //no imprint selected
      optionarea.replaceChildren();
      this.product.updatePrice();
      return;
    }

    const imprintoptionpulldown = <select class="promidata-imprintoption__pulldown form-select">
      {position.imprintoptions.map(option => <option value={option.sku}>{option.title}</option>)}
    </select>;

    optionarea.replaceChildren(<div>
      {imprintoptionpulldown}
    </div>);

    imprintoptionpulldown.addEventListener("change", () => this._onImprintOptionChange());
    this.product.updatePrice();
  }

  _onImprintOptionChange() {
    this.product.updatePrice();
  }

  //list currently selected options (expect size), sorted by ID to allow stable comparisons after JSON.stringify
  _getCurrentOptions() {
    return this.product.getSelectedOptions().map(_ => _.optionid).sort((lhs, rhs) => lhs - rhs);
  }

  _describeCurrentConfiguration(baseprice: string): PromidataConfiguration | null {
    const curoptions = this._getCurrentOptions();
    const selectedoptions_str = JSON.stringify(curoptions);
    let totalquantity = 0;
    const imprintselection = [];
    const costs = new Array<PromidataConfigurationCostLine & { nodes?: DocumentFragment }>;
    const itemselection = [];
    let validorder = true;
    let requiredminimum: number | null = null;
    const minimumpersize = this.productinfo.minimumpersize || null;

    let anyonrequest = false;
    this._currentquantity = 0;

    const applicableprices = this.productinfo.pricematrix.filter(row => JSON.stringify(row.options) == JSON.stringify(curoptions));

    for (const size of (this.productinfo.sizes.length ? this.productinfo.sizes : ['']))
      totalquantity += this.getQuantityForSize(size);

    for (const size of (this.productinfo.sizes.length ? this.productinfo.sizes : [''])) {
      const quantity = this.getQuantityForSize(size);
      if (quantity <= 0)
        continue; //irrelevant

      if (quantity > 10000) {
        console.log("quantity overflow");
        return null;
      }

      const priceinfo = size ? applicableprices.filter(row => row.size === size) : applicableprices;
      if (priceinfo.length != 1) {
        console.log({ size, quantity, curoptions }, this.productinfo.pricematrix);
        console.log({ priceinfo });
        console.error(`${priceinfo.length == 0 ? "No" : "Ambiguous"} priceinfo found (size=${size}, quantity=${quantity}, options = ${JSON.stringify(curoptions)}`);
        return null;
      }

      if (minimumpersize && quantity < minimumpersize) { //if any size doesn't hit the minimum, the order is invalid - forresult/attentie#18
        validorder = false;
        anyonrequest = true;
      }

      const quantityfordiscounts = this.productinfo.countallsizes ? totalquantity : quantity;
      const quantityprice = getPiecePrice(priceinfo[0].prices, quantityfordiscounts);
      if (quantityprice === '0') { //explicitly no available price
        anyonrequest = true;
      }

      const minQuantityForThisSize = priceinfo[0].prices[0]?.quantity ?? 1;
      if (requiredminimum === null || requiredminimum < minQuantityForThisSize) //not yet set
        requiredminimum = minQuantityForThisSize;

      itemselection.push({ size, quantity, sku: priceinfo[0].sku });
      costs.push({
        sku: priceinfo[0].sku,
        nodes: size ? <>
                <span class="product__selectedoptions__perpiece">Prijs p/st</span> <span class="product__selectedoptions__size"> {size}</span> <span class="product__selectedoptions__amountprefix"><span>bij afname</span></span> <span class="product__selectedoptions__amount">{quantity} st.</span>
                </> : <>Prijs p/st. bij afname {quantity} st.</>,
        title: 'Prijs p/st. bij afname ' + quantity + ' st.',
        costs: finmath.multiply(quantity, quantityprice),
        costsperpiece: finmath.formatPrice(finmath.roundToMultiple(quantityprice, "0.01", "up"), ",", 2),
        onrequest: false
      });
    } //for size

    if (totalquantity == 0) { //we need 'something' to give an estimate the per-piece price
      validorder = false;
      /* requiredminimum can't be set yet as even a 'minimum not hit' would still update totalquantity. assume we can take
         the first price and that all minimums are the same (the warnings and price tables need more work anyway if they aren't) */
      requiredminimum = applicableprices[0]?.prices[0]?.quantity ?? 1;
      costs.push({ sku: 'no selection', title: 'U heeft nog geen aantal geselecteerd', costs: baseprice, costsperpiece: "0", onrequest: true });
    }

    if (requiredminimum && totalquantity < requiredminimum) { //we now match the *total* of all sizes - forresult/attentie#18
      validorder = false;
    }

    const imprints = this.productinfo.imprints.find(row => JSON.stringify(row.options) == selectedoptions_str)?.imprintpositions ?? [];
    for (const imprint of imprints) {
      const matchingsection = dompack.qSA(this.node, '.promidata-imprint').find(node => node.querySelector<HTMLSelectElement>('.promidata-imprintposition__pulldown')!.value == imprint.code);
      if (!matchingsection)
        continue;

      const selectedoption = imprint.imprintoptions.find(option => option.sku == matchingsection.querySelector<HTMLSelectElement>('.promidata-imprintoption__pulldown')!.value)!;
      const onrequest = selectedoption.onrequest || false;

      if (selectedoption) {
        imprintselection.push({
          position: imprint.code,
          option: selectedoption.sku,
          positiontitle: imprint.title,
          optiontitle: selectedoption.title
        });

        costs.push({
          sku: selectedoption.sku,
          title: selectedoption.title,
          costs: finmath.multiply(getPiecePrice(selectedoption.prices, totalquantity), totalquantity || 1), //if no quantity, calculate for 1
          costsperpiece: finmath.formatPrice(finmath.roundToMultiple(finmath.divide(finmath.multiply(getPiecePrice(selectedoption.prices, totalquantity), totalquantity || 1), totalquantity || 1), "0.01", "up"), ",", 2),
          onrequest: (onrequest === true) ? true : false
        });

        selectedoption.imprintcosts.forEach(fixedcost => {
          costs.push({
            sku: fixedcost.sku,
            title: fixedcost.title,
            costs: fixedcost.prices[0].price,
            costsperpiece: finmath.formatPrice(finmath.roundToMultiple(fixedcost.prices[0].price, "0.01", "up"), ",", 2),
            onrequest: (onrequest === true) ? true : false
          });
        });

        if (onrequest === true)
          anyonrequest = true;
      }
    }


    this._currentquantity = totalquantity;
    const orderdetails = `${this.product.getProductTitle()}\n`
      + this.product.getSelectedOptions().map(_ => _.label + ": " + _.selected) + "\n"
      + itemselection.map(_ => _.quantity + "x " + _.sku + (_.size ? " " + _.size : "")).join("\n") + "\n"
      + imprintselection.map(_ => _.positiontitle + ": " + _.optiontitle + " (" + _.option + ")").join("\n");
    offerteform.configureQuote(this.product.getProductTitle(), orderdetails, {
      id: this.product.id ?? this.product.productinfo.id, //internal data
      options: this.product.getSelectedOptions(),
      itemselection,
      imprintselection
    });

    const insertionpoint = dompack.qS('.product__selectedoptions');
    if (insertionpoint)
      insertionpoint.replaceChildren(
        <table class="table table-borderless table-sm">
          <tbody>{costs.map(_ =>
            <tr><td><span class="product__selectedoptions__title">{_.nodes ?? _.title} </span></td>
              <td><span class="product__selectedoptions__price text-end">{((_.costsperpiece == "0" || _.costsperpiece == "0,00") ? "" : _.costsperpiece) + ((_.onrequest === true) ? "op aanvraag" : "")}</span>
              </td>
            </tr>)}
          </tbody></table>);

    let finalprice = '0';
    costs.filter(row => row.costs).forEach(row => finalprice = finmath.add(finalprice, row.costs));
    if (finmath.test(finalprice, "<", this.minimumprice))
      anyonrequest = true;

    document.documentElement.classList.toggle("product--priceonrequest", anyonrequest);
    const config = { totalquantity, costs, imprintselection, itemselection, validorder, requiredminimum, anyonrequest, finalprice, minimumpersize };
    return config;
  }


  calculateConfiguration(): PromidataSelectedConfiguration {
    const res = this._describeCurrentConfiguration('0');
    if (!res)
      throw new Error(`Invalid configuration`);

    const config: PromidataSelectedConfiguration = {};
    if (res.itemselection.length) //strip odd cells
      config.items = res.itemselection.map(({ size, quantity }) => ({ size, quantity }));
    if (res.imprintselection.length) {
      //stabilize and cleanup config to allow direct comparisons
      config.imprints = res.imprintselection.sort((lhs, rhs) => lhs.position < rhs.position ? -1 : lhs.position == rhs.position ? 0 : 1);
      config.imprints = config.imprints.map(({ position, option }) => ({ position, option }));
    }

    return config;
  }

  _getPrice(baseprice: string) {
    const res = this._describeCurrentConfiguration(baseprice);
    if (!res?.validorder) {
      //we may not actually get prices for all options
      console.log("Price calculation failed: No configuration possible");
      return null;
    }

    // console.group(`Calculated costs: ${finalprice} for ${res.totalquantity} items`);
    // console.table(res.costs);
    // console.groupEnd();

    return {
      finalprice: res.finalprice,
      totalquantity: res.totalquantity,
      anyonrequest: res.anyonrequest
    };

  }

  override calculatePrice(baseprice: string/*finmath.FinmathInput*/, hashparams: URLSearchParams, amount: number): string | "onrequest" {
    this._checkPriceTablesUpdate();

    const priceinfo = this._getPrice(baseprice);
    return priceinfo?.finalprice ?? baseprice;
  }
}

export default PromidataProduct;
