/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import '@mod-webshop/css/defaultshop.scss';

import ListCartWidget from '@mod-webshop/js/widgets/listcart';
import CheckoutWidget from '@mod-webshop/js/widgets/checkout';
import ConfimrQuoteWidget from '@mod-webshop/js/widgets/confirmquote';
import { _updateAddedProductPrice } from '@mod-webshop/js/widgets/addedcartproduct';
import RequestPaymentWidget from '@mod-webshop/js/widgets/requestpayment';
import SearchWidget from '@mod-webshop/js/widgets/search';
import Webshop from '@mod-webshop/js/shopservice';
import { registerHandler, RPCFormBase } from '@mod-publisher/js/forms';
import * as favlist from "../shopservice/favlist";

let webshop = null;

function filterListCartOptions(options) {
  const filtered = {
    onNewCartDom: options.onNewCartDom,
    inlinediscounts: options.inlinediscounts
  };
  return filtered;
}
function filterSearchOptions(options) {
  return {
  };
}
function filterCheckoutOptions(options) {
  return { couponRenderer: options.couponRenderer };
}
function filterConfirmQuoteOptions(options) {
  return {
  };
}

function initDefaultShop(options) {
  if (!options)
    options = {};

  if (webshop)
    throw new Error("Duplicate webshop initialization");

  webshop = new Webshop(options);
  dompack.register('webshop-cart', node => new ListCartWidget(webshop, node, filterListCartOptions(options)));
  dompack.register('.webshop-search', node => new SearchWidget(webshop, node, filterSearchOptions(options)));
  dompack.register('.webshop-retrypaymentlink', node => node.addEventListener("click", evt => { dompack.stop(evt); webshop.retryPayment(node.dataset.retrydata); }));
  dompack.register("input.webshop-product__favorite", node => favlist.setupFavorite(webshop, node));
  dompack.register('.webshop-addedcartproduct', node => _updateAddedProductPrice(webshop, node));
  registerHandler('webshop:checkout', node => new CheckoutWidget(webshop, node, filterCheckoutOptions(options)));
  registerHandler('webshop:confirmquote', node => new ConfimrQuoteWidget(webshop, node, filterConfirmQuoteOptions(options)));
  registerHandler('webshop:login', node => new RPCFormBase(node));
  registerHandler('webshop:paymentrequest', node => new RequestPaymentWidget(webshop, node));
}

export function getWebshop() {
  return webshop;
}

export function setup(options) {
  dompack.onDomReady(() => initDefaultShop(options));
}

if (dompack.debugflags.sho && whintegration.config.obj.webshoppagedata)
  console.log("[sho] pagedata", whintegration.config.obj.webshoppagedata);

if (whintegration.config.webshop?.cached)
  console.log(`Cached page - generated at ${whintegration.config.webshop?.cached} (${Math.floor((Date.now() - Date.parse(whintegration.config.webshop?.cached)) / 1000)} seconds ago)`);

//Add helpful devolopment APIs
window.webshopResetCheckout = function () {
  webshop.resetCheckoutForm();
};
