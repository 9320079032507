import { Money } from "./temp_money";
import { FinmathInput } from "@mod-system/js/util/finmath";

export const WebShopNumDecimals = 2; //We could make this configurable but it's rare and we have no real CI that it actually works!

export interface CategoryOptionFilter {
  optionlists: number[];
  optionvalues: number[];
}
export interface CategoryFilter {
  /** Filter configuration as put on the URL */
  filtersetting: string;
  /** Individual optionbased filters */
  optionfilters: CategoryOptionFilter[];
}
export type OnCalculatePriceCallback = (price: string/*finmath.FinmathInput*/, _hash: URLSearchParams, amount: number) => FinmathInput;

export interface CalculateProductPricesFromInfoOptions {
  amount?: number;
  options?: number[];
  oncalculateprice?: OnCalculatePriceCallback;
  hash: URLSearchParams;
  withexquantityprice?: boolean;
}

interface RawProductPriceInfo {
  i: number; //database id
  f?: number[];
  o: Array<{
    o: number;
    p: number;
    r: number;
    s: number;
    v: Array<{
      o: number;
      v: number;
      e?: string;
      l?: string;
    }>;
  }>;
}

export class ProductPriceInfo {
  productid: number;
  fixedoptions: number[];
  productoptions: Array<{
    optionlist: number;
    productoption: number;
    //r? s?
    values: Array<{
      optionvalue: number;
      productvalue: number;
      extraprice: Money;
      //l? listprice? but at this level... ?
    }>;
  }>;

  constructor(productnode: HTMLElement) {
    const json = JSON.parse(productnode.dataset.webshopDynpriceinfo!) as RawProductPriceInfo;
    //convert the compressed JSON struture to readable price info
    this.productid = json.i;
    this.fixedoptions = json.f ?? [];
    this.productoptions = json.o.map(productoption => ({
      optionlist: productoption.o,
      productoption: productoption.p,
      values: productoption.v.map(productvalue => ({
        optionvalue: productvalue.o,
        productvalue: productvalue.v,
        extraprice: new Money(productvalue.e ?? "0"),
      }))
    }));
  }
}
