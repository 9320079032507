import * as cookie from 'dompack/extra/cookie';
import { WebShop } from "./index";
import { getDynPriceInfo } from './product';

export function setupFavorite(webshop: WebShop, node: HTMLInputElement) {
  let productid: number = 0;

  const productinfonode = node.closest<HTMLElement>("[data-webshop-product]");
  if (productinfonode)
    productid = JSON.parse(productinfonode.dataset.webshopProduct!).id;

  const productidnode = node.closest<HTMLElement>("[data-webshop-productid]");
  if (productidnode)
    productid = parseInt(productidnode.dataset.webshopProductid!);

  const productdyninfonode = node.closest<HTMLElement>("[data-webshop-dynpriceinfo]");
  if (productdyninfonode)
    productid = getDynPriceInfo(productdyninfonode).productid;

  if (!productid) {
    console.error("Cannot locate product attributes for favlist icon", node);
    return;
  }

  const favlist = cookie.read("wh-webshop-favlist-" + node.value);
  if (favlist) {
    try {
      const favlistitems = JSON.parse(favlist);
      if (favlistitems.ids.includes(productid))
        node.checked = true;
    } catch (ignore) {
      //do not crash everything just because someone messed up their cookies
      cookie.remove("wh-webshop-favlist-" + node.value, { path: webshop.getCatalogPathname() });
    }
  }
  node.addEventListener("change", () => handleFavoriteChange(webshop, node, productid));
}

function handleFavoriteChange(webshop: WebShop, node: HTMLInputElement, productid: number) {
  //If we get here, we should be able to assume the cookie list is readable
  const favlist = cookie.read("wh-webshop-favlist-" + node.value);
  const currentids = favlist ? JSON.parse(favlist).ids : [];
  const currentlyset = currentids.includes(productid);
  if (currentlyset == node.checked)
    return; //nothing to change!

  if (node.checked)
    currentids.push(productid);
  else
    currentids.splice(currentids.indexOf(productid), 1);

  if (currentids.length)
    cookie.write("wh-webshop-favlist-" + node.value, JSON.stringify({ ids: currentids }), { path: webshop.getCatalogPathname() });
  else
    cookie.remove("wh-webshop-favlist-" + node.value, { path: webshop.getCatalogPathname() });
}
